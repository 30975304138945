import React, { useEffect, useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { kleedStyle } from '../../components/Navbar/styles';
import useWindowDimensions from '../../useWindowDimensions';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';

const Kleden = () => {
    const { width, height } = useWindowDimensions();
    let popMargin = 264;
    window.onload = function (){
        let imageList = document.getElementById('imageList'); 
        popMargin = 264 + ((width - 270 - imageList.offsetWidth - 4 ) / 2)
    }
    
    let newMargin = '278px'
    if(popMargin < (264 + ((width - 270 - 1240 - 4 ) / 2)))
    {
        popMargin = 264 + ((width - 270 - 1240 - 4 ) / 2)
    }
    
    let titleMargin = '10px'
    let popAnch = 132

    let popHeight = height - 55
    let maxPopImageWidth = '800px'
    
    if (width < 900){
     newMargin = '8px'
     popMargin = 8
     maxPopImageWidth = width/1.5
     titleMargin = '-30px'
     popAnch = 60
    }

    const [data, setData] = useState([]);

    const checkData = (data) => {
        if (data[0] === 'error')
        {
            alert('Er was een probleem bij het laden van de kleden. Probeer het later opnieuw.');
        }
        else{
            setData(data);
        }
    }

    useEffect(() => {
        fetch('https://fotos.laatstereiskleed.nl/api/getitems')
        .then(res => res.json())
        .then(data => checkData(data))
        .catch(err => alert('Er was een probleem bij het laden van de kleden. Probeer het later opnieuw.'));
    }, []);

    const [targetPop, setTargetPop] = React.useState('');

    const handleClick = (event) => {
        setTargetPop(event.currentTarget.alt);
      };

      const handleClose = () => {
        setTargetPop('');
      };

    const bigScreenList = () => (
        <div style={{marginLeft:newMargin, marginRight:'8px', width:'100%'}}>
            <h1 style={{fontFamily:'Andalan', fontSize:'96px', textAlign:'center', margin:'10px', marginTop:titleMargin}}>Kleden</h1>
            <ImageList id='imageList' gap={4} sx={{ maxWidth: '1240px', height: '100%', margin:'auto' }}>
                {data.map((KleedItems) => (
                    <React.Fragment key={KleedItems.naam + '_<ReactFragment>'}>
                        <ImageListItem key={KleedItems.naam + '_<imageListItem>'}>
                            <img
                            key={KleedItems.naam + '_<img>'}
                            src={KleedItems.url}
                            alt={KleedItems.naam}
                            loading="lazy"
                            aria-owns={KleedItems.naam}
                            
                            onClick={handleClick}
                            style={{cursor:'pointer', objectFit:'cover', maxWidth:'618px', height:'438px'}}
                            />
                            <ImageListItemBar
                            key={KleedItems.naam + '_<imageListItemBar>'}
                            title={KleedItems.naam}
                            subtitle={<span>{KleedItems.afmeting}</span>}
                            position="below"
                            sx={kleedStyle}
                            />
                        </ImageListItem>
                        <Popover
                            key={KleedItems.naam + '_<popover>'}
                            id={KleedItems.naam}
                            open={targetPop === KleedItems.naam}
                            anchorReference='anchorPosition'
                            anchorPosition={{top:popAnch, left:popMargin}}
                            onClose={handleClose}
                            placement='bottom-start'
                        >
                            <Grid 
                                key={KleedItems.naam + '_<GridMain>'}
                                container spacing={2} 
                                sx={{maxWidth:'1240px', width:'100%', height: '100%', margin: 'auto', backgroundColor:'#D4D0CD'}}>
                                <Grid key={KleedItems.naam + '_<Gridimg>'} xs={'auto'}>
                                    <img
                                        key={KleedItems.naam + '_<popimg>'}
                                        src={KleedItems.url}
                                        alt={KleedItems.naam}
                                        loading="lazy"
                                        style={{pointerEvents: 'none', objectFit:'contain', maxWidth:maxPopImageWidth, maxHeight:popHeight}}
                                        />
                                </Grid>
                                <Grid key={KleedItems.naam + '_<gridText>'} xs>
                                    <div key={KleedItems.naam + '_<div>'} style={{margin:'auto'}}>
                                        <p key={KleedItems.naam + '_<p1>'}>
                                            <b key={KleedItems.naam + '_<bNaam>'}>Naam:</b> {KleedItems.naam} <br/>
                                            <b key={KleedItems.naam + '_<bAfmetingen>'}>Afmetingen:</b> {KleedItems.afmeting} <br />
                                            <b key={KleedItems.naam + '_<bMateriaal>'}>Materiaal:</b> {KleedItems.materiaal} <br />
                                            <b key={KleedItems.naam + '_<bprijs>'}>Prijs:</b> €{KleedItems.prijs} <br />
                                        </p>
                                        <p key={KleedItems.naam + '_<p2>'}>
                                            <b key={KleedItems.naam + '_<bOmschrijving>'}>Omschrijving:</b> <br />
                                            {KleedItems.omschrijving}
                                        </p>
                                    </div>
                                </Grid>
                                <Grid key={KleedItems.naam + '_<gridCloseButton>'} xs={'auto'} sx={{padding:'0px'}}>
                                    <IconButton key={KleedItems.naam + '_<IconButtonClose>'} aria-label='Sluiten' onClick={handleClick}>
                                        <CloseIcon key={KleedItems.naam + '_<closeIcon>'} fontSize='small'/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Popover>
                    </React.Fragment>
                ))}
            </ImageList>
        </div>
    )

    const smallScreenList = () => (
        <div style={{marginLeft:newMargin, marginRight:'8px', width:'100%'}}>
            <h1 style={{fontFamily:'Andalan', fontSize:'96px', textAlign:'center', margin:'10px', marginTop:titleMargin}}>Kleden</h1>
            <ImageList id='imageList' gap={4} sx={{ maxWidth: '1240px', height: '100%', margin:'auto' }}>
                {data.map((KleedItems) => (
                    <React.Fragment key={KleedItems.naam + '_<ReactFragment>'}>
                        <ImageListItem key={KleedItems.naam + '_<imageListItem>'}>
                            <img
                            key={KleedItems.naam + '_<img>'}
                            src={KleedItems.url}
                            alt={KleedItems.naam}
                            loading="lazy"
                            aria-owns={KleedItems.naam}
                            
                            onClick={handleClick}
                            style={{cursor:'pointer', objectFit:'cover', maxWidth:'618px', height:'438px'}}
                            />
                            <ImageListItemBar
                            key={KleedItems.naam + '_<imageListItemBar>'}
                            title={KleedItems.naam}
                            subtitle={<span>{KleedItems.afmeting}</span>}
                            position="below"
                            sx={kleedStyle}
                            />
                        </ImageListItem>
                        <Popover
                            key={KleedItems.naam + '_<popover>'}
                            id={KleedItems.naam}
                            open={targetPop === KleedItems.naam}
                            anchorReference='anchorPosition'
                            anchorPosition={{top:popAnch, left:popMargin}}
                            onClose={handleClose}
                            placement='bottom-start'
                        >
                            <Grid 
                                key={KleedItems.naam + '_<GridMain>'}
                                container spacing={2} 
                                sx={{maxWidth:'1240px', width:'100%', height: '100%', margin: 'auto', backgroundColor:'#D4D0CD'}}>
                                <Grid key={KleedItems.naam + '_<Gridimg>'} xs={11} sx={{paddingRight:'0px'}}>
                                    <img
                                        key={KleedItems.naam + '_<popimg>'}
                                        src={KleedItems.url}
                                        alt={KleedItems.naam}
                                        loading="lazy"
                                        style={{pointerEvents: 'none', objectFit:'contain', maxWidth:'100%', maxHeight:popHeight}}
                                        />
                                </Grid>
                                <Grid key={KleedItems.naam + '_<gridCloseButton>'} xs={1} sx={{padding:'0px'}}>
                                    <IconButton key={KleedItems.naam + '_<IconButtonClose>'} aria-label='Sluiten' onClick={handleClick} sx={{padding:'0px'}}>
                                        <CloseIcon key={KleedItems.naam + '_<closeIcon>'} fontSize='small' />
                                    </IconButton>
                                </Grid>
                                <Grid key={KleedItems.naam + '_<gridText>'} xs>
                                    <div key={KleedItems.naam + '_<div>'} style={{margin:'auto'}}>
                                        <p key={KleedItems.naam + '_<p1>'}>
                                            <b key={KleedItems.naam + '_<bNaam>'}>Naam:</b> {KleedItems.naam} <br/>
                                            <b key={KleedItems.naam + '_<bAfmetingen>'}>Afmetingen:</b> {KleedItems.afmeting} <br />
                                            <b key={KleedItems.naam + '_<bMateriaal>'}>Materiaal:</b> {KleedItems.materiaal} <br />
                                            <b key={KleedItems.naam + '_<bprijs>'}>Prijs:</b> €{KleedItems.prijs} <br />
                                        </p>
                                        <p key={KleedItems.naam + '_<p2>'}>
                                            <b key={KleedItems.naam + '_<bOmschrijving>'}>Omschrijving:</b> <br />
                                            {KleedItems.omschrijving}
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Popover>
                    </React.Fragment>
                ))}
            </ImageList>
        </div>
    )



    const render = () => {
        if(width > 450){
            return(bigScreenList());
        }
        else{
            return(smallScreenList())
        }
    }

    return(
        render()
    );
};

export default Kleden;