import Toolbar from '@mui/material/Toolbar';
import { mainNavbarItems } from './const/navbarItems';
import { navbarStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import name from '../../img/Name.jpg'

import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useWindowDimensions from '../../useWindowDimensions';

export default function AnchorTemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, number) => {
    setSelectedIndex(number);
  };

  const  navigate = useNavigate();

  const HidingNavbar = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     <Drawer
        sx = {navbarStyles.drawer}
        variant = "permanent"
        anchor = "left"
      >
        <Toolbar style={{padding:0}}>
          <a href='/'><img src={name} alt='Logo' style={{objectFit:'contained'}}/></a>
        </Toolbar>
        <Divider />
        <List>
          {mainNavbarItems.map((item, index) => (
            <ListItem 
                key = {item.id} disablePadding
                onClick={() => navigate(item.route)}>
              <ListItemButton
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}>
                <ListItemIcon sx = {navbarStyles.icons}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText   sx = {navbarStyles.text}
                                primary = {item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div style={{position: 'absolute', bottom: '2%', marginLeft: '20px', width:'100%'}}>
          mail: mijn@mail.com<br/>
          nummer: +31 12345678<br/>
          kvk: nummer<br />
          <div style={{fontSize: '18px'}}>&copy; 2024 bedrijfsnaam</div>
        </div>

      </Drawer> 
    </Box>
  );

  const Navbar = () => (
      <Drawer
        sx = {navbarStyles.drawer}
        variant = "permanent"
        anchor = "left"
      >
        <Toolbar style={{padding:0}}>
          <a href='/'><img src={name} alt='Logo' style={{objectFit:'contained'}}/></a>
        </Toolbar>
        <List>
          {mainNavbarItems.map((item, index) => (
            <ListItem 
                key = {item.id} disablePadding
                onClick={() => navigate(item.route)}>
              <ListItemButton
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}>
                <ListItemIcon sx = {navbarStyles.icons}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText   sx = {navbarStyles.text}
                                primary = {item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div style={{position: 'absolute', bottom: '2%', marginLeft: '20px', width:'100%'}}>
          mail: mijn@mail.com<br/>
          nummer: +31 12345678<br/>
          kvk: nummer<br />
          <div style={{fontSize: '18px'}}>&copy; 2024 bedrijfsnaam</div>
        </div>

      </Drawer>
  );

  const { width } = useWindowDimensions();

  const render = () => {
    if (width < 900){
      return(<div style={{ position:'sticky', top:'0', zIndex:'999'}}>
        <React.Fragment key={'left'}>
          <IconButton id='drawerIcon' onClick={toggleDrawer('left', true)}><MenuIcon sx={{color:'#274e13'}} /></IconButton>
          <Drawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
          >
            {HidingNavbar('left')}
          </Drawer>
        </React.Fragment>
      </div>)
    }
    else{
      return(Navbar())
    }
  };

  return (
    render()
  );
}