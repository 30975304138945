import React from 'react';
import AnchorTemporaryDrawer from './components/Navbar/Navbar';
import { Outlet } from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2';
import { Helmet } from 'react-helmet';

const csp = ` 
  default-src 'self'; 
  script-src 'self' 'unsafe-inline'; 
  style-src 'self' 'unsafe-inline'; 
  img-src 'self' data: https://fotos.laatstereiskleed.nl/img/; 
  font-src 'self' data:;
  connect-src 'self' https://fotos.laatstereiskleed.nl/api/getitems; 
`; 

function App() {
  return (
    <>
      <Helmet>
        <meta  
          http-equiv="Content-Security-Policy" 
          content={csp} /> 
      </Helmet>
      <Grid container>
        <AnchorTemporaryDrawer />
        <Outlet />
      </Grid>
    </>
  );
};

export default App;
