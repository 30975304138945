export const navbarStyles = {
    drawer: {
        width: 320,
        flexShrink: 0,
        '& .MuiDrawer-paper':{
            maxWidth: '270px',
            boxSizing: 'border-box',
            backgroundColor: '#274e13',
            color: '#FFFFFF',
            overflow: 'hidden',
        },
    },
    icons: {
        color: '#FFFFFF',
        marginLeft: '20px',
    },
    text: {
        '& .MuiListItemText-primary':{
            fontFamily: 'Andalan',
        },
        '& span' : {
            marginLeft: '-10px',
            fontWeight: '600',
            fontSize: '48px'
         }
    }
}

export const kleedStyle = {
    // backgroundColor: '#3a134e',
    backgroundColor: '#274e13',
    color: '#ffffff',
    textAlign:'center',
}