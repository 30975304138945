import HomeIcon from '@mui/icons-material/Home';
import PanoramaIcon from '@mui/icons-material/Panorama';
import PersonIcon from '@mui/icons-material/Person';
import ContactPageIcon from '@mui/icons-material/ContactPage';

export const mainNavbarItems = [
    {
        id: 0,
        icon: <HomeIcon />,
        label: 'Home',
        route: '/',
    },
    {
        id: 1,
        icon: <PanoramaIcon />,
        label: 'Kleden',
        route: 'kleden',
    },
    {
        id: 2,
        icon: <PersonIcon />,
        label: 'Over mij',
        route: 'about',
    },
    {
        id: 3,
        icon: <ContactPageIcon />,
        label: 'Contact',
        route: 'contact',
    },
]