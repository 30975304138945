import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/home/home';
import Kleden from './pages/kleden/kleden';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import { ThemeProvider } from '@mui/material/styles';
import { Theme } from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={Theme}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element = {<App />}>
          <Route path='/' element = {<Home />} />
          <Route path='kleden' element = {<Kleden />} />
          <Route path='about' element = {<About />} />
          <Route path='contact' element = {<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

reportWebVitals();
