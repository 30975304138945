import React from 'react';
import useWindowDimensions from '../../useWindowDimensions';
import Grid from '@mui/material/Unstable_Grid2';
import foto from '../../img/foto.jpg'

const About = () => {
    const { width } = useWindowDimensions();
    let newMargin = '278px'
    let titleMargin = '10px'
    if (width < 900){
     newMargin = '8px'   
     titleMargin = '-30px'
    }

    return(
        <div style={{marginLeft:newMargin, marginRight:'8px', width:'100%'}}>
            <h1 style={{fontFamily:'Andalan', fontSize:'96px', textAlign:'center', margin:'10px', marginTop:titleMargin}}>Over mij</h1>
            <div style={{ maxWidth: '1240px', height: '100%', margin:'auto' }}>
                <Grid container spacing={2}> 
                    <Grid xs={6}>
                        <p style={{textAlign:'center', maxWidth:'500px'}}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Praesent facilisis lectus id fermentum cursus. Donec laoreet libero at urna rhoncus placerat.
                        Phasellus lacinia vitae leo in venenatis. Cras dolor urna, cursus non diam vitae, consectetur sollicitudin ante. 
                        Duis auctor diam ut egestas dictum. Praesent lacinia accumsan enim sit amet dictum. Vivamus auctor elit ut rutrum ullamcorper. 
                        Aliquam erat volutpat. Suspendisse iaculis est sapien, et facilisis leo pharetra nec.
                        </p>
                    </Grid> 
                    <Grid xs={6}>
                        <img src={foto} alt='mijn foto' style={{width:'100%', pointerEvents: 'none'}}/>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
};

export default About;