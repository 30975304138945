import { createTheme } from '@mui/material/styles';


export const Theme = createTheme({
  typography: {
    fontFamily: `'HaveIdea', 'Arial'`,
    fontSize: 23,
  },
  palette: {
    primary: {
        main: '#274e13',
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected':{
            backgroundColor: '#3a134e'
          },
          '&:hover': {
            backgroundColor: ' #3a134e !important' 
          },
        },
      },  
    },
  }, 
}); 