import React from 'react';
import useWindowDimensions from '../../useWindowDimensions';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import { Button, MenuItem } from '@mui/material';

const Contact = () => {
    const { width } = useWindowDimensions();
    let newMargin = '270px'
    let titleMargin = '10px'
    if (width < 900){
     newMargin = '8px'   
     titleMargin = '-30px'
    }

    const subjects = [
        {
            value: 'Kopen',
            label: 'Laatste Reiskleed kopen',
        },
        {
            value: 'Interesse',
            label: 'Interesse in workshop',
        },
        {
            value: 'Anders',
            label: 'Anders (schrijf in het bericht waarom) ',
        },
    ];

    return(
        <div style={{marginLeft:newMargin, marginRight:'8px', width:'100%'}}>
            <h1 style={{fontFamily:'Andalan', fontSize:'96px', textAlign:'center', margin:'10px', marginTop:titleMargin}}>Contact</h1>
            <div style={{ maxWidth: '700px', height: '100%', margin:'auto' }}>
                <p style={{textAlign:'center'}}>
                    Je kunt met mij in contact komen door een mail te sturen naar mijn@mail.nl of door het onderstaand formulier in te vullen
                </p>
                <form>
                    <Grid container spacing={2}>

                        <Grid xs={12}>
                            <TextField
                                name='Naam'
                                fullWidth
                                required
                                id="outlined-required"
                                label="Naam"
                            />
                        </Grid>
                        <Grid xs={12}>
                            <TextField
                                name='Mail'
                                fullWidth
                                required
                                type='email'
                                id="outlined-required"
                                label="E-mail"
                            />
                        </Grid>
                        <Grid xs={12}>
                            <TextField
                                fullWidth
                                required
                                id="outlined-required"
                                label="Onderwerp"
                                select
                                defaultValue=""
                            >
                                {subjects.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid xs={12} >
                            <TextField
                                name='Bericht'
                                fullWidth
                                required
                                multiline rows={5}
                                id="outlined-required"
                                label="Bericht"
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Button type="submit" variant='contained' fullWidth>Submit</Button>
                        </Grid>
                    
                    </Grid>
                </form>
            </div>

        </div>
    )
};

export default Contact;