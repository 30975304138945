import React from 'react';
import useWindowDimensions from '../../useWindowDimensions';
import homeImage from '../../img/Home1.jpg';

const Home = () => {
    const { width, height } = useWindowDimensions();
    let newMargin = '270px'
    let titleMargin = '110px'
    let imageHeight = '100vh'
    if (width < 900){
     newMargin = '0px'   
     titleMargin = '-30px'
     imageHeight = height - 55.422
    }
    if(width < 450){
        imageHeight = '100%'
    }

    return (
        <div style={{marginLeft:newMargin, width:'100%', backgroundImage:`url(${homeImage})`, 
                        backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%',
                        height:imageHeight}}>
            <h1 style={{fontFamily:'Andalan', fontSize:'96px', textAlign:'center', margin:'10px', marginTop:titleMargin}}>Laatste Reiskleed</h1>
            <div style={{ maxWidth: '700px', margin:'auto',  marginTop:'60px'}}>
                <p style={{textAlign:'center', padding:'10px'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean commodo quam vel varius vestibulum. 
                Nam condimentum ante venenatis, condimentum ipsum consequat, hendrerit orci. 
                Cras vitae suscipit tellus, sit amet porta leo. Suspendisse egestas rutrum tellus id accumsan. 
                Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. 
                <br /><br />
                Phasellus tincidunt, ante eget laoreet facilisis, tortor tortor convallis nulla, id faucibus magna eros ut ipsum. 
                Integer varius risus nibh, at consequat arcu facilisis iaculis. Curabitur augue magna, dapibus a nisl ut, viverra tincidunt eros. 
                Nam consequat purus a sapien iaculis rutrum. Nullam pretium odio id orci efficitur, ac tempor urna tempus. 
                Donec vel purus at tortor feugiat scelerisque eget ac eros. Sed porta, orci quis consequat dignissim, velit risus tincidunt ante, 
                sed luctus quam urna ac leo. Curabitur dapibus pharetra lorem vitae sodales. In ultricies rhoncus purus. Nullam in ultrices ligula. 
                Nunc aliquam mollis eros, vitae tempus dui feugiat pretium.
                <br /><br />
                Vivamus auctor suscipit neque ac dapibus. Donec consequat metus justo, at pharetra lectus faucibus vitae. 
                Fusce pretium diam nec magna tincidunt, non scelerisque leo fermentum. Duis malesuada ipsum at est faucibus, ac cursus odio placerat. 
                Fusce vestibulum, magna ac maximus vestibulum, libero mauris elementum lectus, nec ultricies metus mi mollis dui. 
                <br /><br />
                In arcu elit, aliquam eget luctus lobortis, cursus ut dui. Praesent vehicula turpis eu metus luctus vehicula quis non massa. 
                Proin gravida hendrerit commodo. Vestibulum vulputate nulla quis metus convallis, convallis vestibulum leo porta. Quisque facilisis, 
                velit eu lobortis accumsan, nunc dui fringilla dui, in tempor quam purus ut massa. In rutrum vulputate odio, porta efficitur metus feugiat vel. 
                Donec nisl urna, convallis in faucibus a, mattis et ex. Ut rhoncus velit eget efficitur volutpat.
                </p>
            </div>
        </div>
    )
};

export default Home;